import React from 'react';

const LinkedinIcon = (props) => {
  return (
    <span  className={`${props.class ? props.class : ''}`}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.3292 2C21.252 2 22 2.74752 22 3.67079V20.3292C22 21.252 21.2525 22 20.3292 22H3.67079C2.74804 22 2 21.2525 2 20.3292V3.67079C2 2.74804 2.74752 2 3.67079 2H20.3292ZM15.5187 9.5C14.0993 9.5 13.1281 10.1274 12.6957 10.8563L12.625 10.9858V9.625H9.91667V19.2083H12.8333V14.5662C12.8333 13.0104 13.4062 12.0208 14.7833 12.0208C15.7331 12.0208 16.1316 12.8607 16.1644 14.358L16.1667 14.5662V19.2083H19.0833V13.9154C19.0833 11.0575 18.3996 9.5 15.5187 9.5ZM7.83333 9.5H4.91667V19.0833H7.83333V9.5ZM6.375 4.5C5.33958 4.5 4.5 5.33958 4.5 6.375C4.5 7.41042 5.33958 8.25 6.375 8.25C7.41042 8.25 8.25 7.41042 8.25 6.375C8.25 5.33958 7.41042 4.5 6.375 4.5Z" fill="#333333"/>
      </svg>
    </span>
    );
  }
  
  export default LinkedinIcon;