import React from 'react';

const LogoIcon = (props) => {
  return (
    <span  className={`${props.class ? props.class : ''}`}>
      <svg width="54" height="51" viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1557 25.7053L28.2531 7.64472C28.5962 7.31969 28.8737 6.91201 29.0664 6.45014C29.2591 5.98827 29.3622 5.48334 29.3687 4.97061C29.3687 2.88892 29.089 0.00012207 27.0002 0.00012207C26.0023 0.0149955 23.815 1.65436 23.0794 2.43432L2.54988 22.6177C2.48227 22.6732 2.41644 22.7329 2.35239 22.7946C1.94401 23.1177 1.60827 23.5489 1.37308 24.0523C1.1379 24.5557 1.01008 25.1168 1.00024 25.6888L1.00036 25.7053C2.92362 25.7053 10.1557 25.7053 10.1557 25.7053Z" fill="#74FDE6"/>
        <path d="M43.9698 25.0215L44.0002 25.0001C44.0002 25.0001 43.9762 24.5088 43.9698 25.0215L53.1252 25.0359C53.0612 25.0955 53.1928 24.9804 53.1252 25.0359C53.1202 24.5621 53.0322 24.0943 52.8664 23.661C52.7007 23.2276 52.4607 22.8375 52.1609 22.5143L52.088 22.4423L31.0003 1.50011C30.0003 0.500114 28.4762 -0.00874156 27.5003 0.000113614C25.691 0.000113614 25.2034 2.17907 25.2034 4.26051C25.2034 5.32797 25.2674 5.98409 26.0929 7.18318L43.9698 25.0215Z" fill="#4C29FF"/>
        <path d="M24.0002 3.35788C24.0002 4.36347 24.112 4.94085 24.9224 6.07044L27.371 8.50012L29.9052 6.07044C30.2419 5.76428 30.5144 5.38028 30.7035 4.94523C30.8926 4.51019 30.9939 4.03459 31.0002 3.55164C31.0002 1.97641 30.0432 0.64532 28.6058 0.176435C28.2337 0.0590905 27.8487 -0.000245274 27.4618 0.000123781C26.5276 0.0157321 25.6284 0.396531 24.9224 1.07546C24.6342 1.36398 24.4034 1.71594 24.2446 2.10892C24.0859 2.50189 24.0026 2.92725 24.0002 3.35788Z" fill="#40D4FA"/>
        <path d="M43.9895 25.0001L25.892 43.0607C25.549 43.3857 25.2715 43.7934 25.0788 44.2552C24.8861 44.7171 24.7829 45.222 24.7765 45.7348C24.7765 47.8165 24.9315 50.6701 27.0202 50.6701C28.8756 50.5701 30.3302 49.051 31.0658 48.2711L51.5953 28.0877C51.6629 28.0321 51.7287 27.9725 51.7928 27.9108C52.2012 27.5877 52.5369 27.1565 52.7721 26.6531C53.0073 26.1497 53.1203 25.5721 53.1301 25.0001L53.0002 25.0001C51.0769 25.0001 43.9895 25.0001 43.9895 25.0001Z" fill="#4C29FF"/>
        <path d="M10.1755 25.4609C10.1755 25.4609 2.98021 25.3306 1 25.5C1.06405 25.4404 0.932393 25.5555 1 25.5C1.00502 25.9738 1.11309 26.611 1.27883 27.0444C1.44457 27.4778 1.68457 27.8679 1.98433 28.1911L2.05728 28.2631L23.1449 49.2053C24.1449 50.2053 25.669 50.7141 26.6449 50.7053C28.4543 50.7053 28.9419 48.5263 28.9419 46.4449C28.9419 45.3774 28.8778 44.7213 28.0523 43.5222L10.1755 25.4609Z" fill="#74FDE6"/>
        <path d="M30.145 47.3475C30.145 46.3419 30.0332 45.7645 29.2229 44.6349L26.7743 42.2053L24.2401 44.6349C23.9033 44.9411 23.6309 45.3251 23.4418 45.7601C23.2526 46.1952 23.1513 46.6708 23.145 47.1537C23.145 48.729 24.1021 50.0601 25.5395 50.5289C25.9116 50.6463 26.2966 50.7056 26.6834 50.7053C27.6176 50.6897 28.5169 50.3089 29.2229 49.6299C29.511 49.3414 29.7419 48.9894 29.9006 48.5965C30.0594 48.2035 30.1426 47.7781 30.145 47.3475Z" fill="#40D4FA"/>
      </svg>
    </span>
  );
}

export default LogoIcon;