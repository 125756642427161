import React from 'react';

const DevicesIcon = (props) => {
  return (
    <span  className={`${props.class ? props.class : ''}`}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
    <path d="M5.49992 11H38.4999V7.33331H5.49992C3.48325 7.33331 1.83325 8.98331 1.83325 11V33C1.83325 35.0166 3.48325 36.6666 5.49992 36.6666H12.8333V33H5.49992V11ZM23.8333 22H16.4999V25.2633C15.3816 26.2716 14.6666 27.7016 14.6666 29.3333C14.6666 30.965 15.3816 32.395 16.4999 33.4033V36.6666H23.8333V33.4033C24.9516 32.395 25.6666 30.9466 25.6666 29.3333C25.6666 27.72 24.9516 26.2716 23.8333 25.2633V22ZM20.1666 32.0833C18.6449 32.0833 17.4166 30.855 17.4166 29.3333C17.4166 27.8116 18.6449 26.5833 20.1666 26.5833C21.6882 26.5833 22.9166 27.8116 22.9166 29.3333C22.9166 30.855 21.6882 32.0833 20.1666 32.0833ZM40.3332 14.6666H29.3332C28.4166 14.6666 27.4999 15.5833 27.4999 16.5V34.8333C27.4999 35.75 28.4166 36.6666 29.3332 36.6666H40.3332C41.2499 36.6666 42.1666 35.75 42.1666 34.8333V16.5C42.1666 15.5833 41.2499 14.6666 40.3332 14.6666ZM38.4999 33H31.1666V18.3333H38.4999V33Z" fill="#74FDE6"/>
    </g>
    <defs>
    <clipPath>
    <rect width="44" height="44" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    </span>
    );
  }
  
  export default DevicesIcon;